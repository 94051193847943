@charset "UTF-8";
body,
html {
  margin: 0;
}
body.-changelog,
html.-changelog {
  overflow: hidden;
}
.col-980 {
  max-width: 980px;
  padding: 0 8%;
  margin: 0 auto;
}
#svg-defs {
  width: 0;
  height: 0;
  position: absolute;
  left: -100%;
  top: -100%;
}
* {
  font-family: Nunito, sans-serif;
  -webkit-font-smoothing: antialiased;
}
.header {
  padding: 145px 0;
}
@media (max-width: 800px) {
  .header {
    padding: 40px 0;
  }
}
.header > .col-980 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .header > .col-980 {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.header .right {
  max-width: 360px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
@media (max-width: 800px) {
  .header .right {
    text-align: center;
    margin-bottom: 50px;
  }
}
.header .logo {
  overflow: hidden;
  margin: 0;
}
.header .logo a {
  color: #000;
  text-decoration: none;
  font-weight: 700;
  font-size: 31px;
}
.header .description {
  max-width: 290px;
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 21px;
  font-weight: 300;
  color: #4c4c4c;
  line-height: 1.5;
}
@media (max-width: 800px) {
  .header .description {
    max-width: none;
  }
}
.header .cta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 450px) {
  .header .cta {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
  }
}
.header .action {
  padding: 13px 25px 17px 25px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  outline: 0;
  transition: all 0.25s ease;
  font-weight: 700;
}
.header .action:hover {
  transform: translateY(-2px);
}
.header .action:hover.-buy {
  box-shadow: 0 8px 25px 0 rgba(46, 139, 196, 0.3),
    inset 0 -4px 8px 0 rgba(0, 0, 0, 0.05);
}
.header .action:hover.-video {
  box-shadow: 0 0 2px 0 rgba(44, 47, 51, 0.21),
    0 8px 25px 0 rgba(233, 233, 234, 0.8);
}
.header .action.-buy {
  margin-right: 10px;
  background: #4ca9ff;
  box-shadow: 0 2px 10px 0 rgba(46, 139, 196, 0.3),
    inset 0 -4px 8px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #fff;
}
@media (max-width: 450px) {
  .header .action.-buy {
    margin-right: 0;
  }
}
.header .action.-video {
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(44, 47, 51, 0.21),
    0 3px 8px 0 rgba(233, 233, 234, 0.8);
  border-radius: 4px;
  color: #4a4a4c;
}
@media (max-width: 450px) {
  .header .action.-video {
    margin-top: 20px;
  }
}
.header .action .icon {
  width: 8px;
  height: 10px;
  margin-right: 11px;
}
.header .trial-cta {
  display: inline-block;
  position: relative;
  margin-top: 25px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  color: #4ca9ff;
}
.header .trial-cta:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  z-index: 2;
  background-color: rgba(76, 169, 255, 0.8);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out 0s;
}
.header .trial-cta:hover:after {
  visibility: visible;
  transform: scaleX(1);
}
.header .left {
  position: relative;
  margin-right: 100px;
  cursor: pointer;
}
@media (max-width: 800px) {
  .header .left {
    margin: 0;
  }
}
.header .left:hover .overlay {
  opacity: 1;
}
.header .left:hover .play {
  opacity: 1;
  transform: scale(1);
}
.header .left:hover .play:hover {
  transform: scale(0.9);
}
.header .screen {
  max-width: 100%;
  height: auto;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}
.header .overlay {
  width: 100%;
  height: calc(100% - 4px);
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  opacity: 0;
  transition: all 0.1s ease;
}
.header .play {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  border-radius: 100%;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.25s ease;
  opacity: 0;
  transform: scale(0.9);
}
.header .play:hover {
  transform: scale(0.9);
}
@media (max-width: 900px) and (min-width: 801px), (max-width: 450px) {
  .header .play {
    zoom: 0.75;
  }
}
.header .play .icon {
  width: 16px;
  height: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -6px;
  margin-top: -10px;
}
.demo {
  padding: 85px 0;
  background-color: #fbfbfb;
}
@media (max-width: 800px) {
  .demo {
    padding: 40px 0;
  }
}
.demo .item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 100px;
}
.demo .item.-reverse {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.demo .item:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 800px) {
  .demo .item {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .demo .item.-reverse {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.demo .left {
  max-width: 350px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
@media (max-width: 800px) {
  .demo .left {
    text-align: center;
    margin-bottom: 50px;
  }
}
.demo .left.-reverse {
  margin-left: 100px;
}
@media (max-width: 800px) {
  .demo .left.-reverse {
    margin-left: 0;
  }
}
.demo .heading {
  font-size: 31px;
  font-weight: 700;
  line-height: 1.5;
}
.demo .description {
  margin: 0;
  margin-top: 22px;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.5;
  color: #4c4c4c;
}
.demo .right {
  position: relative;
  margin-left: 100px;
}
@media (max-width: 800px) {
  .demo .right {
    margin-left: 0;
  }
}
.demo .right.-reverse {
  margin-left: 0;
}
.demo .screen {
  max-width: 100%;
  height: auto;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.1s ease;
  border-radius: 3px;
}
.demo .before {
  display: block;
  max-width: 100%;
  pointer-events: none;
}
.demo .after {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
.demo .after img {
  max-height: 100%;
}
.demo .handle {
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 1;
  pointer-events: none;
}
.features {
  overflow: hidden;
  padding: 150px 0;
}
@media (max-width: 1100px) {
  .features {
    padding: 95px 0;
  }
}
@media (max-width: 400px) {
  .features {
    padding: 90px 0;
  }
}
.features .col-980 {
  overflow: hidden;
}
@media (max-width: 1100px) {
  .features .col-980 {
    padding: 0 12%;
  }
}
.features .feature {
  width: 29%;
  float: left;
  margin-right: 6.5%;
  margin-bottom: 120px;
}
@media (min-width: 1096px) {
  .features .feature:nth-child(3n) {
    margin-right: 0;
  }
  .features .feature:nth-child(4),
  .features .feature:nth-child(5),
  .features .feature:nth-child(6) {
    margin-bottom: 0;
  }
}
@media (min-width: 651px) and (max-width: 1095px) {
  .features .feature {
    width: 46%;
    min-height: 92px;
    margin-right: 8%;
    margin-bottom: 100px;
  }
  .features .feature:nth-child(2n) {
    margin-right: 0;
  }
  .features .feature:nth-child(5),
  .features .feature:nth-child(6) {
    margin-bottom: 0;
  }
}
@media (max-width: 650px) {
  .features .feature {
    width: 100%;
    margin-right: 0;
    margin-bottom: 80px;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .features .feature:last-of-type {
    margin-bottom: 0;
  }
}
@media (max-width: 400px) {
  .features .feature {
    margin-bottom: 75px;
  }
}
.features .feature .icon {
  width: 36px;
  height: 36px;
}
.features .feature .name {
  margin-top: 15px;
  margin-bottom: 23px;
  font-size: 25px;
  font-weight: 700;
  color: #000;
}
.features .feature .description {
  margin: 15px 0 0 0;
  font-size: 17px;
  color: #4c4c4c;
  font-weight: 300;
  letter-spacing: 0.3px;
  line-height: 25px;
  -webkit-font-smoothing: subpixel-antialiased;
}
.testimonials {
  padding: 90px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  background-color: #fbfbfb;
}
.testimonials .heading {
  margin-bottom: 85px;
  font-size: 25px;
  font-weight: 700;
  color: #000;
  text-align: center;
}
.testimonials .wrap {
  display: -ms-flexbox;
  display: flex;
}
.testimonials .wrap.-animate {
  transition: all 0.3s ease;
}
.testimonials .review {
  width: 350px;
  margin-right: 75px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.3;
  transition: opacity 0.3s ease;
}
@media (max-width: 1000px) {
  .testimonials .review {
    width: 300px;
  }
}
.testimonials .review:last-of-type {
  margin-right: 0;
}
.testimonials .review.active {
  opacity: 1;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.testimonials .review:not(.active) {
  cursor: pointer;
}
.testimonials .review:not(.active) * {
  pointer-events: none;
}
.testimonials .avatar {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
.testimonials .triangle {
  width: 48px;
  height: 36px;
  position: relative;
  z-index: 1;
  margin-top: 9px;
}
.testimonials .content {
  margin-top: -24px;
  padding: 18px 24px;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(44, 47, 51, 0.1),
    0 3px 7px 0 rgba(165, 165, 175, 0.2);
  border-radius: 4px;
}
.testimonials .text {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 17px;
  color: #000;
  letter-spacing: 0;
  line-height: 1.5;
}
.testimonials .author {
  font-size: 13px;
  color: #4c4c4c;
}
.testimonials .author a {
  text-decoration: none;
  color: #4c4c4c;
}
.testimonials .author a:hover {
  text-decoration: underline;
}
.buy {
  padding: 120px 0;
}
@media (max-width: 800px) {
  .buy {
    padding: 110px 0;
  }
}
.buy .col-980 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.buy .content {
  text-align: center;
}
.buy .name {
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 31px;
  font-weight: 700;
}
.buy .cta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 435px) {
  .buy .cta {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
  }
}
.buy .action {
  padding: 13px 25px 17px 25px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  outline: 0;
  transition: all 0.25s ease;
  font-weight: 700;
}
.buy .action:hover {
  transform: translateY(-2px);
}
.buy .action:hover.-buy {
  box-shadow: 0 8px 25px 0 rgba(46, 139, 196, 0.3),
    inset 0 -4px 8px 0 rgba(0, 0, 0, 0.05);
}
.buy .action:hover.-video {
  box-shadow: 0 0 2px 0 rgba(44, 47, 51, 0.21),
    0 8px 25px 0 rgba(233, 233, 234, 0.8);
}
.buy .action.-buy {
  margin-right: 20px;
  background: #4ca9ff;
  box-shadow: 0 2px 10px 0 rgba(46, 139, 196, 0.3),
    inset 0 -4px 8px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #fff;
}
@media (max-width: 435px) {
  .buy .action.-buy {
    margin-right: 0;
  }
}
.buy .action.-video {
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(44, 47, 51, 0.21),
    0 3px 8px 0 rgba(233, 233, 234, 0.8);
  border-radius: 4px;
  color: #4a4a4c;
}
@media (max-width: 435px) {
  .buy .action.-video {
    margin-top: 20px;
  }
}
.buy .action .icon {
  width: 8px;
  height: 10px;
  margin-right: 11px;
}
.buy .trial-cta {
  display: inline-block;
  position: relative;
  margin-top: 30px;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  color: #4ca9ff;
}
.buy .trial-cta:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  z-index: 2;
  background-color: rgba(76, 169, 255, 0.8);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out 0s;
}
.buy .trial-cta:hover:after {
  visibility: visible;
  transform: scaleX(1);
}
.buy .info {
  margin-top: 30px;
  font-size: 15px;
  color: #a2a2a2;
  letter-spacing: 0.38px;
  line-height: 1.9;
}
.buy .info .mobile {
  display: none;
}
.buy .info .separator {
  margin: 0 13px;
}
@media (max-width: 400px) {
  .buy .info {
    font-size: 14px;
  }
  .buy .info .normal {
    display: none;
  }
  .buy .info .mobile {
    display: block;
  }
}
.buy .info .buyversion {
  cursor: pointer;
}
.footer .col-980 {
  height: 110px;
}
.footer .content {
  height: 110px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  border-top: 2px solid #fafafa;
}
@media (max-width: 800px) {
  .footer .content {
    height: 130px;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 800px) {
  .footer .left {
    margin-bottom: 15px;
  }
}
.footer .text {
  font-size: 15px;
  color: #7f7f81;
  letter-spacing: 0.4px;
}
.footer .text a {
  color: #000;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}
.footer .text a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out 0s;
}
.footer .text a:hover:after {
  visibility: visible;
  transform: scaleX(1);
}
.footer .text.separator {
  margin: 0 15px;
}
@media (max-width: 400px) {
  .footer .text.separator {
    margin: 0 10px;
  }
}
.dootech {
  width: 310px;
  position: fixed;
  top: 20px;
  right: 20px;
  box-sizing: border-box;
  padding: 18px 23px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1337;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(44, 47, 51, 0.1),
    0 3px 7px 0 rgba(165, 165, 175, 0.2);
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  pointer-events: none;
  opacity: 0;
  transform: translateX(100px);
  transition: all 0.3s ease-in-out;
}
.dootech.-show {
  pointer-events: all;
  opacity: 1;
  transform: translateX(0);
}
@media (max-width: 1000px) {
  .dootech.-show {
    pointer-events: none;
    opacity: 0;
    transform: translateX(100px);
  }
}
.dootech .left {
  margin-right: 10px;
}
.dootech .heading {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 17px;
  color: #000;
  letter-spacing: 0;
}
.dootech .description {
  font-size: 13px;
  line-height: 1.5;
  color: #4c4c4c;
}
.dootech .icon {
  width: 40px;
  height: 38px;
  border-radius: 4px;
}
.dootech .close {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}
.ph {
  width: 100%;
  padding: 10px 10px 10px 13px;
  box-sizing: border-box;
  background-color: #171717;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  letter-spacing: 0.2px;
  text-align: center;
  font-weight: 400;
}
.ph .bold {
  font-weight: 700;
  color: #fff;
}
.video {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  padding: 4%;
  display: none;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  transition: opacity 0.2s ease;
}
.video.show {
  display: -ms-flexbox;
  display: flex;
}
.video.animate {
  opacity: 1;
}
.video > .player {
  max-width: 100%;
  max-height: 100%;
}
.video > .close {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  cursor: pointer;
}
.video > .close > .icon {
  width: 22px;
  height: 22px;
}
.trial {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  padding: 4%;
  display: none;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.2s ease;
}
.trial.show {
  display: -ms-flexbox;
  display: flex;
}
.trial.animate {
  opacity: 1;
}
.trial .content {
  max-width: 650px;
  position: relative;
  padding: 60px;
  box-sizing: border-box;
  text-align: left;
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1), 0 12px 24px 0 rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}
.trial .heading {
  margin-bottom: 25px;
  font-size: 25px;
  font-weight: 700;
  color: #000;
}
.trial .info {
  margin-bottom: 40px;
  font-size: 17px;
  color: #4c4c4c;
  font-weight: 300;
  letter-spacing: 0.3px;
  line-height: 25px;
  -webkit-font-smoothing: subpixel-antialiased;
}
.trial .info a {
  color: #4ca9ff;
  text-decoration: none;
  position: relative;
  font-weight: 400;
}
.trial .info a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(76, 169, 255, 0.8);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out 0s;
}
.trial .info a:hover:after {
  visibility: visible;
  transform: scaleX(1);
}
.trial .action {
  padding: 13px 25px 17px 25px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  outline: 0;
  transition: all 0.25s ease;
  font-weight: 700;
}
.trial .action:hover {
  transform: translateY(-2px);
}
.trial .action:hover.-download {
  box-shadow: 0 8px 25px 0 rgba(46, 139, 196, 0.3),
    inset 0 -4px 8px 0 rgba(0, 0, 0, 0.05);
}
.trial .action.-download {
  background: #4ca9ff;
  box-shadow: 0 2px 10px 0 rgba(46, 139, 196, 0.3),
    inset 0 -4px 8px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #fff;
}
.trial .close {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  cursor: pointer;
}
.newfeatures {
  text-align: center;
}
.newfeatures .heading {
  font-size: 24px;
  font-weight: 300;
}
.newfeatures .action {
  padding: 13px 25px 17px 25px;
  margin-top: 40px;
  margin-bottom: 120px;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  outline: 0;
  transition: all 0.25s ease;
  font-weight: 700;
  background: #f35507;
  color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1),
    inset 0 -4px 8px 0 rgba(0, 0, 0, 0.05);
}
.newfeatures .action:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.1),
    inset 0 -4px 8px 0 rgba(0, 0, 0, 0.05);
}
.changelog {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: none;
  z-index: 9999;
  box-sizing: border-box;
  background-color: rgba(250, 250, 251, 0.9);
  opacity: 0;
  transition: opacity 0.2s ease;
}
.changelog.-show {
  display: block;
}
.changelog.-animate {
  opacity: 1;
}
.changelog > .close {
  width: 22px;
  height: 22px;
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px;
  cursor: pointer;
}
@media (max-width: 760px) {
  .changelog > .close {
    top: 5px;
    right: 5px;
  }
}
@media (max-width: 860px) {
  .changelog > .close {
    position: absolute;
  }
}
.changelog > .close > .icon {
  width: 22px;
  height: 22px;
}
.changelog .content {
  max-width: 700px;
  margin: 100px auto;
  padding: 60px 100px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.2s ease;
}
@media (max-width: 760px) {
  .changelog .content {
    margin: 50px 4%;
    padding: 8.5% 14%;
  }
}
.changelog .content.-show {
  opacity: 1;
  transform: scale(1);
}
.changelog .version {
  margin-top: 65px;
  font-weight: 300;
  font-size: 28px;
  color: #2a2a2a;
}
.changelog .version:first-of-type {
  margin-top: 0;
}
@media (max-width: 760px) {
  .changelog .version {
    margin-top: 50px;
  }
}
.changelog ul {
  padding: 0;
  font-size: 16px;
  color: #737376;
  font-weight: 300;
  letter-spacing: 0.3px;
  line-height: 1.7;
  list-style: none;
}
.changelog ul * {
  -webkit-font-smoothing: subpixel-antialiased;
}
.changelog ul li {
  margin: 15px 0;
}
.changelog ul li:before {
  content: "— ";
  color: #2a2a2a;
  margin-right: 7px;
  margin-left: -21px;
}
.changelog .bold {
  font-weight: 400;
  color: #2a2a2a;
}
.changelog video {
  width: calc(100% + 100px);
  margin-left: -50px;
  margin-top: 10px;
  margin-bottom: 25px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.changelog a {
  color: #0099d8;
  font-weight: 700;
  text-decoration: none;
}
.changelog a:hover {
  text-decoration: underline;
}

.netlify-form {
  zoom: 1;
}
.netlify-form:before,
.netlify-form:after {
  display: table;
  line-height: 0;
  content: "";
}
.netlify-form:after {
  clear: both;
}
.netlify-form * {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box !important;
  float: left !important;
  font-size: 16px;
}
.netlify-form input {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  line-height: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-right: 0;
  color: rgba(0, 0, 0, 0.65);
  padding: 10px;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.02);
  background-position: top right;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: caret;
  width: 65% !important;
  height: 40px !important;
}
.netlify-form button {
  font-weight: 700;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-transition: all 0.05s ease-in-out;
  transition: all 0.05s ease-in-out;
  display: inline-block;
  padding: 11px 15px 12px;
  cursor: pointer;
  color: #fff;
  line-height: 100%;
  background: #f35507;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), inset 0 -4px 8px 0 rgba(0, 0, 0, 0.05);
  height: 40px !important;
  width: 35% !important;
}
.netlify-form button:hover {
  background: #f89900;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.1), inset 0 -4px 8px 0 rgba(0, 0, 0, 0.05);
}
